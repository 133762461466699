import React, { useState } from 'react';
import { modalManagerModel } from '@common/services/modal-manager';
import { IS_SITE } from '@common/consts';
import { ROUTES } from '@website/shared/lib/routes';
import ModalButton from '@common/components/modals/ModalButton';
import { analyticsDictionary, createAnalyticClickAttribute } from '@analytics';
import { useUnit } from 'effector-react';
export const Button = ({ buttonClassName, type, btnText, onClick }) => {
    const [isLoader, setIsLoader] = useState(false);
    const closeModal = useUnit(modalManagerModel.closeModal);
    const handleClick = async () => {
        try {
            setIsLoader(true);
            if (onClick) {
                await onClick();
                return;
            }
            closeModal();
            if (IS_SITE) {
                const nextRouter = require('next/router');
                const { pathname } = nextRouter.default;
                const noNeedRedirectToProfile = pathname.includes('profile') || pathname === ROUTES.gift;
                if (noNeedRedirectToProfile) {
                    return;
                }
                nextRouter.default.push(ROUTES.profile);
            }
        }
        finally {
            setIsLoader(false);
        }
    };
    return (<>
      <ModalButton className={buttonClassName} size='l' mode='primary' onClick={handleClick} loading={isLoader} disabled={isLoader} {...createAnalyticClickAttribute(analyticsDictionary.modalCheckoutSuccessButton(type))}>
        {btnText}
      </ModalButton>
    </>);
};
