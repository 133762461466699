import React from 'react';
import { analyticsDictionary } from '@analytics';
import { Button } from './ui/Button';
import { showSubscriptionSuccessFormedModal } from '@common/components/modals/SubscriptionSuccessFormedModal';
import { modalManagerTools } from '@common/services/modal-manager';
import { APP_ID } from '@common/consts';
export const showSuccessfulPaymentModal = (params) => {
    const { platform, title, buttonText, type, description, buttonClassName, mode, onClick } = params;
    if (platform === 'android' || platform === 'ios') {
        if (APP_ID !== 'ru_vkvideo_miniapp') {
            showSubscriptionSuccessFormedModal({ platform, type, title, description });
            return;
        }
    }
    const btnText = buttonText || 'Хорошо';
    modalManagerTools.modalTemplates.showMessageModal({
        analyticShowAttribute: analyticsDictionary.modalCheckoutSuccessShow(type),
        header: title,
        subheader: description,
        mode,
        actions: (<Button buttonClassName={buttonClassName} type={type} btnText={btnText} onClick={onClick}/>),
    });
};
